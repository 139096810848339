
.btn-ghost-variant(@color, @text-color: white) {
	border-color: @color;
	color: @color;

	&:hover {
		background-color: @color;
		color: @text-color;
	}
}

.btn {
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 0px;
    font-size: 12px;
    line-height: 1.42857;
    vertical-align: middle;
    .transition( all 0.25s ease 0s );
}

.btn-lg, .btn-group-lg > .btn {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    // border-radius: 6px;
}

.btn-sm, .btn-group-sm > .btn {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    // border-radius: 3px;
}

.btn-xs, .btn-group-xs > .btn {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    // border-radius: 3px;
}

.btn-ghost {
	background-color: transparent;
	border-width: 2px;

	&.btn-default {
		.btn-ghost-variant(white, @gray-darker);
	}

	&.btn-success {
		.btn-ghost-variant(@brand-success);
	}

	&.btn-warning {
		.btn-ghost-variant(@brand-warning);
	}

	&.btn-danger {
		.btn-ghost-variant(@brand-danger);
	}

	&.btn-info {
		.btn-ghost-variant(@aqua-lt);
	}
}

.btn-round {
	border-radius: 50%;
}

.btn-group {
	.dropdown-menu {
		min-width: 120px;
		padding: 5px;

		> li {
			> a {
				font-size: 12px;
				padding: 7px 10px;
				border-radius: 2px;
				.transition(all 0.2s ease-out 0s);

				> i {
					font-size: 13px;
					margin-right: 8px;
					width: 16px;
					color: #999;
				}
			}
		}
	}
}
