.navbar {
	margin-bottom: 0;
	box-shadow: 0px 2px 0px -1px rgba(0, 0, 0, 0.05);

	.navbar-right {
		margin-right: -11px;
	}

	&.navbar-default {
		border: none;
		background-color: white;

		.navbar-collapse {
			padding-left: 15px;
			padding-right: 15px;
			box-shadow: -4px 0px 0px 0px @brand-primary inset;

			ul:first-child {
				margin-left: 15px;
			}
		}
	}

	.navbar-nav {
		> li {
			> .dropdown-menu {
				margin-top: 10px;
				li {
					a {
						padding: 10px 15px;

						.fa, i {
							font-size: 1.33em;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
}

.navbar-header {
	background-color: @brand-primary;
	padding-left: -15px;

	.navbar-brand {
		padding: 0;
	}
	.brand-icon, .brand-title {
		display: block;
		line-height: 50px;
	}

	.brand-title {
		color: white;
		padding: 0px 1.5em 0px 5px;
	}

	.brand-icon {
		.square(50px);
		float: left;
	}
}

.dropdown-menu {
	font-size: 12px;
	border-radius: 0px;
	box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.15);
	margin: 1px 0px 0px;
	padding: 0px;
	border: 1px solid #E9E9E9;

	&.with-arrow {
		&:before, &:after {
		    content: "";
		    display: block;
		    position: absolute;
		    width: 0px;
		    height: 0px;
		    left: 0px;
		    top: 0px;
		    border-left: 7px solid transparent;
		    border-right: 7px solid transparent;
		}
		&:before{
			border-bottom:7px solid #d0d0d0;
			margin:-7px 0 0 15px
		}
		&:after{
			border-bottom:7px solid #fff;
			margin:-6px 0 0 15px
		}
	}

	&.dropdown-menu-right {
		&.with-arrow {
			&:before{
				left:auto;
				right:0;
				margin:-7px 25px 0 0
			}

			&:after{
				left:auto;
				right:0;
				margin:-6px 25px 0 0
			}
		}
	}
}

.page-container {
	&.sidebar-lg {
		.navbar-brand {
			width: 220px;
		}
	}

	&.sidebar-sm {
		.navbar-brand {
			width: 50px;
		}
	}
}
