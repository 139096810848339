// main: quest.less

@media screen and (max-width: 767px) {
	.row-offcanvas {
		position: relative;
		-webkit-transition: all .25s ease-out;
			 -o-transition: all .25s ease-out;
				transition: all .25s ease-out;
	}

	.row-offcanvas-right {
		right: 0;
	}

	.row-offcanvas-left {
		left: 0;
	}

	.row-offcanvas-right
	.sidebar-offcanvas {
		right: -50%; /* 6 columns */
	}

	.row-offcanvas-left
	.sidebar-offcanvas {
		left: -50%; /* 6 columns */
	}

	.row-offcanvas-right.active {
		right: 50%; /* 6 columns */
	}

	.row-offcanvas-left.active {
		left: 50%; /* 6 columns */
	}

	.sidebar-offcanvas {
		position: absolute;
		top: 0;
		width: 50%; /* 6 columns */
	}
}
