.panel {
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
	border-radius: 0px;
	border: 0px none;
	margin-bottom: 24px;

	.panel-heading, > :first-child {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

	.panel-heading {
		position: relative;
		height: 50px;
		padding: 0px;

		.panel-control {
			height: 100%;
			position: relative;
			float: right;
			padding: 0px 15px;

			&::before {
				content: "";
				display: inline-block;
				height: 100%;
				vertical-align: middle;
				left: -1em;
				position: relative;
			}

			> i, > .badge, > label {
				vertical-align: middle;
			}

			.nav-tabs {
				display: inline-block;
				height: 40px;
				margin-top: 10px;
				vertical-align: bottom;
				border: 0px none;

				> li {
					margin-top: 1px;
					margin-right: 5px;
					height: 100%;

					&:last-child {
						margin-right: 0;
					}

					&.active > a {
						color: @gray;
						background-color: white;
						border: none;

						&:hover {
							background-color: white;
						}
					}

					> a {
						border-radius: 0px;
						margin-right: 0px;
						height: 100%;
						line-height: 40px;
						padding: 0px 15px;
						border: none;
						color: rgba(255,255,255,0.5);

						&:hover {
							background-color: rgba(0, 0, 0, 0.1);
						}
					}
				}
			}
		}

		.panel-title {
			font-weight: 300;
			padding: 0px 20px;
			font-size: 1.416em;
			line-height: 50px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.panel-title-icon {
				display: inline-block;
				margin-right: 10px;
			}
		}
	}

	.panel-body {
		padding: 20px;
	}
}

.panel, .panel.panel-colorful {
	> .panel-heading::after {
		content: "";
		display: block;
		position: absolute;
		height: 0px;
		left: 20px;
		right: 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}
}

.panel-variants(@color, @text-color: white) {
	background-color: @color;
	border-color: @color;
	color: @text-color;

	&::after {
		border-bottom: none;
	}
}

.panel-primary {
	.panel-heading, .panel-footer, &.panel-colorful {
		.panel-variants(@brand-primary);
	}
}
.panel-info {
	.panel-heading, .panel-footer, &.panel-colorful {
		.panel-variants(@brand-info);
	}
}
.panel-success {
	.panel-heading, .panel-footer, &.panel-colorful {
		.panel-variants(@brand-success);
	}
}
.panel-warning {
	.panel-heading, .panel-footer, &.panel-colorful {
		.panel-variants(@brand-warning);
	}
}
.panel-danger {
	.panel-heading, .panel-footer, &.panel-colorful {
		.panel-variants(@brand-danger);
	}
}
.panel-inverse {
	.panel-heading, .panel-footer, &.panel-colorful {
		.panel-variants(@brand-inverse);
	}
}
