//
// Popovers
// --------------------------------------------------

.popover {
	font-size: 12px;
	border-radius: 0;
	.box-shadow(0 1px 2px rgba(0,0,0,.2));
}

.popover-title {
	font-weight: 300;
	border-radius: 0;
}

.popover-content {
	padding: 10px 15px;
}

// Arrows
//
// .arrow is outer, .arrow:after is inner

.popover > .arrow {
	border-width: 9px;
}

.popover > .arrow:after {
	border-width: 9px;
}

.popover {
	&.top > .arrow {
		bottom: -9px;

		&:after {
			margin-left: -9px;
		}
	}
	&.right > .arrow {
		left: -9px;
		margin-top: -9px;

		&:after {
			bottom: -9px;
		}
	}
	&.bottom > .arrow {
		top: -9px;

		&:after {
			margin-left: -9px;
		}
	}
	&.left > .arrow {
		right: -9px;
		margin-top: -9px;

		&:after {
			bottom: -9px;
		}
	}
}
