.dt-bootstrap {
	.table-header {
		margin-bottom: 20px;
	}
	
	.table-footer {
		margin-top: 10px;
	}
}

.dataTables_length {
	label {
		font-weight: normal;
		text-align: left;
		white-space: nowrap;
	}
	
	select {
		width: 75px;
		display: inline-block;
	}
}

.dataTables_filter {
	text-align: right;
	
	label {
		font-weight: normal;
		white-space: nowrap;
		text-align: left;
	}
	
	input {
		margin-left: 0.5em;
		display: inline-block;
	}
}

.dataTables_info {
	padding-top: 8px;
	white-space: nowrap;
}

.dataTables_paginate {
	margin: 0;
	white-space: nowrap;
	text-align: right;
	
	.pagination {
		margin: 2px 0;
		white-space: nowrap;
	}
}

.dataTable {
	clear: both;
	margin-top: 6px !important;
	max-width: none !important;
	
	td,	th {
		.box-sizing(content-box);
	}
	
	thead {
		.sorting,
		.sorting_asc,
		.sorting_desc,
		.sorting_asc_disabled,
		.sorting_desc_disabled {
			cursor: pointer;
			position: relative;
		}
		
		.sorting:after,
		.sorting_asc:after,
		.sorting_desc:after {
			position: absolute;
			top: 8px;
			right: 8px;
			display: block;
			font-family: FontAwesome;
			opacity: 0.5;
		}
		
		.sorting:after {
			opacity: 0.2;
			content: "\f0dc"; /* sort _ fa-sort */
		}
		
		.sorting_asc:after {
			content: "\f15d"; /* sort-by-attributes _ fa-sort-alpha-asc */
		}
		
		.sorting_desc:after {
			content: "\f15e"; /* sort-by-attributes-alt _ fa-sort-alpha-desc */
		}
		
		.sorting_asc_disabled:after,
		.sorting_desc_disabled:after {
			color: #eee;
		}
		
		> tr > th {
			padding-left: 8px;
			padding-right: 30px;
		}
		
		th:active {
			outline: none;
		}
		
	}
	
	tbody {
		tr {
			&.active {
				td, th {
					background-color: #08C;
					color: white;
					
					&:hover {
						background-color: #0075b0 !important;
					}
					
					&:nth-child(odd) {
						background-color: #017ebc;
					}
					
					> a {
						color: white;
					}
				}
			}
		}
	}
}

/* TableTools information display */
.DTTT_print_info {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 400px;
	height: 150px;
	margin-left: -200px;
	margin-top: -75px;
	text-align: center;
	color: #333;
	padding: 10px 30px;
	opacity: 0.95;

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	
	.box-shadow(0 3px 7px rgba(0, 0, 0, 0.5));
	
	h6 {
		font-weight: normal;
		font-size: 28px;
		line-height: 28px;
		margin: 1em;
	}
	
	p {
		font-size: 14px;
		line-height: 20px;
	}
}

.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 60px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(25%, rgba(255,255,255,0.9)), color-stop(75%, rgba(255,255,255,0.9)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 25%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0) 100%);
}

/* Scrolling */
.dataTables_scrollHead {
	table {
		margin-bottom: 0 !important;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		
		&.table-bordered {
			border-bottom-width: 0;
		}
		
		thead {
			tr:last-child {
				th:first-child, td:first-child {
					border-bottom-left-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}
			}
		}
	}
}

.dataTables_scrollBody {
	table {
		border-top: none;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		
		tbody {
			tr:first-child {
				th, td {
					border-top: none;
				}
			}
		}
	}
}

.dataTables_scrollFoot table {
	margin-top: 0 !important;
	border-top: none;
}

.table-bordered {
	&.dataTable {
		border-collapse: separate !important;
	}
	
	thead, tbody {
		th, td {
			border-left-width: 0;
			border-top-width: 0;
		}
	}
	
	th, td {
		&:last-child {
			border-right-width: 0;
		}
	}
}

/*
 * TableTools styles
 */
.DTTT_selectable {
	tbody {
		tr {
			cursor: pointer;
		}
	}
}

.DTTT {
	.btn {
		&:hover {
			text-decoration: none !important;
		}
	}
}

.DTTT_dropdown {
	&.dropdown-menu {
		z-index: 2003;
		
		a {
			color: #333 !important; /* needed only when demo_page.css is included */
		}
		
		li {
			position: relative;
			
			&:hover a {
				background-color: #0088cc;
				color: white !important;
			}
		}
	}
}

.DTTT_collection_background {
	background: rgba(0, 0, 0, 0.8) !important;
	z-index: 2002;	
}
/*
 * FixedColumns styles
 */
.DTFC_LeftHeadWrapper table, .DTFC_LeftFootWrapper table, 
.DTFC_RightHeadWrapper table, .DTFC_RightFootWrapper table, .DTFC_Cloned {
	tr.even {
		background-color: white;
		margin-bottom: 0;
	}
}

.DTFC_RightHeadWrapper, .DTFC_LeftHeadWrapper {
	table {
		border-bottom: none !important;
		margin-bottom: 0 !important;
		border-top-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
}

.DTFC_RightHeadWrapper, .DTFC_LeftHeadWrapper {
	table {
		thead {
			tr:last-child {
				th:first-child, td:first-child {
					border-bottom-left-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}
			}
		}
	}
}

.DTFC_RightBodyWrapper, .DTFC_LeftBodyWrapper { 
	table {
		border-top: none;
		margin: 0 !important;
	}
}

.DTFC_RightBodyWrapper, .DTFC_LeftBodyWrapper {
	tbody {
		tr:first-child {
			th, td {
				border-top: none;
			}
		}
	}
}

.DTFC_RightFootWrapper, .DTFC_LeftFootWrapper {
	table {
		border-top: none;
		margin-top: 0 !important;
	}
}

/*
 * FixedHeader styles
 */
.FixedHeader_Cloned table {
	margin: 0 !important
}

@media screen and (max-width: 767px) {
	.dataTables_length, .dataTables_filter, .dataTables_info, .dataTables_paginate {
		text-align: center;
	}
}