.nav-folders {
    > li {
        > a {

            & + a {
                border-radius: 0 3px 3px 0;
                position: absolute;
                right: 0;
                top: 0;
                .transition(all 0.2s ease-in 0s);

                &:hover {
                    background-color: darken(@brand-danger, 5%);
                    color: white;
                    border-radius: 5px;
                }
            }

            &:hover {
                & + a {
                    background-color: @brand-danger;
                    color: white;
                }
            }
        }
    }
}

.filemanager-options {
    background-color: white;
    border-radius: 4px;
    margin-bottom: 25px;
    list-style: outside none none;
    .box-shadow(0 1px 2px rgba(0, 0, 0, 0.05));

    > li {
        float: left;
        height: 42px;
        border-right: 1px solid #EEE;

        > a {
            height: 42px;
            color: inherit;
            vertical-align: middle;
        }
    }

    .checkbox {
        margin: 0;
        padding: 10px 15px;

        .icheckbox_square-blue {
            margin-right: 10px;
        }
    }

    > .filter-type {
        float: right;
        border-right: 0px none;
        border-left: 1px solid #EEE;
        padding: 10px 15px;

        > a {
            display: inline-block;
            margin-right: 10px;
            padding: 0;
            height: auto;

            &.active, &:hover {
                background-color: transparent;
                text-decoration: underline;
            }

            &:first-child {
                margin-left: 10px;
            }
        }
    }
}
