// main: quest.less

.note-editor {
	border: none !important;
	border-radius: 3px;
	background: white;
	border-radius: 4px;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);

	.note-toolbar {
		padding: 10px 15px !important;
		border-top: 4px solid lighten(@silver, 15%);
		border-bottom: 1px solid lighten(@silver, 15%) !important;

		> .btn-group {
			margin-top: 0 !important;
		}
	}

}
