@import "bootstrap/bootstrap";
@import "cloudlite/cloudlite";

@btn-font-weight: 300;
@font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
	font-size: 12px;
}

.pace {
	z-index: 1040;

	.pace-progress {
		background: @brand-primary;
	}
}
