.title {
    border-bottom: 2px solid #eee;
    padding-bottom: 7px;
    margin: 15px 0;
}

.text-4x, .text-3x, .text-2x, .text-lg, .text-sm {
    line-height: 1.25;
}

.text-4x {
    font-size: 4em;
}

.text-3x {
    font-size: 3em;
}

.text-2x {
    font-size: 2em;
}

.text-sm {
    font-size: 0.9em;
}

.text-lg {
    font-size: 1.2em;
}
