
.dropzone {
	min-height: 200px;
	border: none;
	background-color: #FFF;
	border-radius: 4px;
    .box-shadow(0 1px 2px rgba(0, 0, 0, 0.05));

	.dz-preview {
		margin: 5px;

		.dz-image {
			border-radius: 8px;
		}
	}

	.dz-message {
		font-size: 1.5em;
		line-height: 2.2;

		&::before {
			font-family: FontAwesome;
			content: "\f0ee";
			display: block;
			font-size: 7em;
			opacity: 0.5;
			line-height: normal;
		}
	}
}
