// Contextual colors
.text-muted {
    color: @text-muted;
}
.text-primary {
    .text-emphasis-variant(@brand-primary);
}
.text-success {
    .text-emphasis-variant(@state-success-text);
}
.text-info {
    .text-emphasis-variant(@state-info-text);
}
.text-warning {
    .text-emphasis-variant(@state-warning-text);
}
.text-danger {
    .text-emphasis-variant(@state-danger-text);
}

// Contextual backgrounds
// For now we'll leave these alongside the text classes until v4 when we can
// safely shift things around (per SemVer rules).
.bg-primary {
    // Given the contrast here, this is the only class to have its color inverted
    // automatically.
    color: #fff;
    .bg-variant(@brand-primary);
}
.bg-success {
    color: #fff;
    .bg-variant(@brand-success);
}
.bg-info {
    color: #fff;
    .bg-variant(@brand-info);
}
.bg-warning {
    color: #fff;
    .bg-variant(@brand-warning);
}
.bg-danger {
    color: #fff;
    .bg-variant(@brand-danger);
}

.blockquote-variant(@color) {
    border-color: @color;
}

.blockquote-primary {
    .blockquote-variant(@brand-primary);
}
.blockquote-success {
    .blockquote-variant(@brand-success);
}
.blockquote-info {
    .blockquote-variant(@brand-info);
}
.blockquote-warning {
    .blockquote-variant(@brand-warning);
}
.blockquote-danger {
    .blockquote-variant(@brand-danger);
}
