
.radio, .radio-inline
.checkbox, .checkbox-inline {
	label {
		padding: 0;

		> div:first-child {
			margin-right: 5px;
		}
	}

	input[type="radio"], input[type="checkbox"] {
		position: relative;
		margin-left: 0;
	    margin-right: 5px;
		vertical-align: top;
	}
}

.form-group {
	label {
		color: @brand-inverse;
	}

	.form-control {
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;
		border-left: none;
		border-right: none;
		border-top-color: transparent;
		border-bottom-color: @gray-lighter;
		padding: 6px 0;
		transition: all ease-in-out .15s;

		&:focus {
			border-color: @brand-primary;
			padding: 6px 12px;
			box-shadow: none;
		}
	}

	select[multiple="multiple"] {
		padding: 0;

		option {
			padding: 6px 12px;
			text-transform: capitalize;
			border-bottom: 1px solid @gray-lighter;
		}

		&:focus {
			padding: 0;
		}
	}
}
