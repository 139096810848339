.progress {
    height: 12px;
    margin-bottom: 15px;
    border-radius: 0px;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.1);

	.progress-bar {
	    font-size: 9px;
	    line-height: 12px;
	    box-shadow: none;
	}
}

.progress-sm {
    height: 4px;
    margin-bottom: 5px;

	.progress-bar {
	    font-size: 0px;
	    line-height: 4px;
	}
}
