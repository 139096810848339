.sidebar {
	background-color: @midnight-blue;
	height: 100%;

	.sidebar-nav-wrap {
		.square(100%);
		overflow: hidden;
		position: relative;
	}
}

.sidebar-shortcut {
	max-height: 50px;
	overflow: hidden;
	.transition(all 0.7s ease 0s);

	ul {
		width: 100%;
		margin: 0;
		padding: 0;

		li {
			padding: 0;
			vertical-align: middle;
			text-align: center;

			.shortcut-grid {
				display: table;
				border: 0px none;
				width: 100%;
				height: 50px;
				color: white;

				> i {
					display: table-cell;
					vertical-align: middle;
					font-size: 15px;
				}
			}
		}
	}

	.sidebar-popover {
		white-space: nowrap;

		.popover-content{
			padding: 12px 15px;
		}
	}
}

@transition-property: 	all, color;
@transition-delay: 		0.3s, 0s;
@transition-duration: 	1.5s, 0.3s;
@timing-function: 		cubic-bezier(0.28, 1.02, 0.36, 1.145);

@transition: 			background-color 0.5s linear 0.5s;
@transition-2: 			background-color 0.5s ease 0s, color 0.5s ease 0s, box-shadow 0.5s ease 0s;
@transition-3: 			background-color 0.2s ease 0s, padding-left 0.3s ease 0s, box-shadow 0.4s ease 0s;
@link-shadow: 			4px 0px 0px 0px @brand-primary inset;

.sidebar-nav {
	.list-header {
		color: @asbertos;
		font-size: 0.9em;
	}

	.list-divider {
		border-top: 1px solid rgba(0, 0, 0, 0.15);
		margin: 5px 0px;
		height: 1px;
	}

	li {
		.transition(@transition);

		&.active-link {
			> a {
				color: white;
				.box-shadow(@link-shadow);

				&:hover, &:focus {
					color: white;
				}
			}
		}

		&.active {
			background-color: darken(@midnight-blue, 3%);
			.transition(@transition-2);

			> a > .arrow::before {
				transform: rotate(135deg);
			}
		}

		> a {
			&:hover, &:focus {
				color: white;
			}
		}

		.arrow, .nav-title > .fa, .nav-title > .badge {
			float: right;
			line-height: 1.42857;
		}

		.arrow {
			&::before {
				content: "";
				border-style: solid;
				border-width: 0.1em 0.1em 0px 0px;
				display: inline-block;
				height: 0.4em;
				left: 0px;
				position: relative;
				top: 0px;
				width: 0.4em;
				transform: rotate(45deg);
				.transition( all 0.3s ease 0s );
			}
		}
	}

	a {
		color: @concrete;
		display: block;
		font-weight: 300;
		padding: 12px 20px;
		.transition(@transition-2);

		strong {
			font-weight: 600;
		}
	}

	i {
		padding: 0px 10px 0px 0px;
	}

	ul {
		list-style: outside none none;
		padding-left: 0;

		a {
			padding: 10px 20px 12px 53px;

			&:hover {
				color: white;
				padding-left: 55px;
			}
		}
	}
}

.sidebar-nav ul, .sidebar-widget ul,
.sidebar-popover ul, .sidebar-popover .sub-menu ul {
    list-style: outside none none;
    padding-left: 0px;
}

.sidebar-nav ul li a,
.sidebar-popover .sub-menu ul li a {
	.transition(@transition-3);
}

.sidebar-widget {
    background-color: rgba(0, 0, 0, 0.05);
    color: @concrete;

	.sidebar-widget-content {
	    padding: 10px 15px;

		.list-header {
			padding: 15px 0px;
			font-size: 0.9em;
		}

		li:not(.list-header) {
			margin-bottom: 15px;
		}
	}

	.show-small a {
	    display: none;
	}
}

.sidebar-lg {
	.main-container, .main-footer {
		padding-left: 220px;
	}
}

@transition-property-2: 	opacity, padding, color, margin;
@transition-duration-2: 	0.5s, 0.5s, 0.1s, 0.5s;
@transition-delay-2: 		0s, 0.5s, 0s, 0.5s;

.sidebar-sm {
	.main-container, .main-footer {
		padding-left: 50px;
	}

	.popover {
		border-radius: 0;
		background-color: @midnight-blue;
		left: 50px !important;
		color: white;
		border: 0;
		padding: 0;
		box-shadow: none;

		&.sidebar-shortcut-popover {
			display: block !important;
			margin-top: -41.14284px;
			margin-left: -1px;
			overflow: hidden;
			width: auto;
			max-width: 300px;
			white-space: nowrap;

			.popover-content {
				padding: 12px 15px;
			}

			&.in {
				.animation(fadeIn 0.2s);

				.popover-content {
					height: 100%;
					margin: 0;
				}
			}
		}

		&.sidebar-popover {
			width: 220px;
			position: absolute;
			margin-left: 0;

			&.in {
				.animation(fadeIn .2s);
			}

			> .popover-title {
				border: 0;
				border-radius: 0;
				padding: 14px 20px;
				font-size: 1.05em;
				position: relative;
				z-index: 5;
				color: white;

				strong {
					font-weight: 300;
				}
			}

			> .popover-content {
				padding: 0;
				width: 100%;
				position: absolute;
				border-radius: 0;

				> .single-content {
					padding: 12px 14px;
					display: block;
					white-space: nowrap;

					strong {
						font-weight: 300;
					}

					.label, .badge {
						margin-left: 10px;
						float: none !important;
					}
				}
			}

			.single-content {
				color: white;
				background-color: @midnight-blue;
			}

			.sub-menu {
				overflow: hidden;

				.collapse {
					display: block;
					visibility: visible;
				}

				ul {
					background-color: darken(@midnight-blue, 5%);

					li > a {
						display: block;
						color: white;
						padding: 12px 17px;
						font-weight: 300;

						&:hover {
							padding-left: 20px;
						}
					}

					ul {
						background-color: darken(@midnight-blue, 8%);

						> li > a {
							padding-left: 27px;

							&:hover{
								padding-left: 33px;
							}
						}
					}
				}
			}

			ul {
				width: 220px;
				margin-bottom: 0;
			}

			.sidebar-widget-content {
				background-color: @midnight-blue;


				> .list-group {
					display: block;
					width: auto;
				}
			}
		}

		> .arrow {
			display:none
		}
	}

	.brand-title, .sidebar .list-header, .sidebar .list-divider, .sidebar .nav-title,
	.sidebar .arrow, .sidebar .nav-title > .label {
		display: none;
		visibility: hidden;
		padding-top: 0px;
		padding-bottom: 0px;
		margin: 0px;
		height: 0px;
		border: none;
		opacity: 0;
		.transition-property(@transition-property-2);
		.transition-duration(@transition-duration-2);
		.transition-delay(@transition-delay-2);
		.transition-timing-function( cubic-bezier(0.28, 1.02, 0.36, 1.145) );
	}

	.sidebar {
		position: relative;

		.sidebar-shortcut {
			max-height: 250px;
			.transition(all 0.7s linear 0.2s);

			li {
				width: 100%;
				text-align: left;
				.transition(all 0.5s ease 0s);

				a {
				    height: auto;
				    display: block;
				    padding: 12px 19px;
				}

				i {
					font-size: 12px;
					line-height: 1.42857;
					.transition(font-size 0.7s ease 0s);
				}
			}
		}

		.sidebar-nav {
			> li {
				display: block;

				> a {
					> .nav-title {
						display: none;
						visibility: hidden;
						padding: 10px;
						position: absolute;
						left: 50px;
						top: 0px;
						z-index: 100;
						min-width: 210px;
						background-color: @midnight-blue;
					}

					&:hover {
						.box-shadow(@link-shadow);
						> .nav-title {
							display: inline !important;
							visibility: visible;
						}
					}

					.arrow {
						display: none;
					}
				}
			}

			> .active-link {
				> a {
					background-color: @brand-primary;
					box-shadow: none;
				}
			}
		}
	}

}

@media (min-width: 768px) {
	.sidebar-sm {
		.sidebar {
			a {
				white-space: nowrap;
				position: relative;
				padding: 12px 0px 12px 19px;

				i {
					display: inline;
				}
			}

			.collapse {
				display: none;
				height: 0px !important;
			}

			.sidebar-widget {
				> .show-small a {
				    display: block;
				    color: @concrete;
				    padding: 12px 0px 12px 19px;
				}

				> .hide-small {
				    display: none;
				}
			}
		}

		.shortcut-grid, .sidebar-widget > .show-small a {
			color: @concrete;

			&:hover {
				color: white;
				.box-shadow(@link-shadow);
				.transition(@transition-3);
			}
		}
	}
}

@media (min-width: 1200px) {
	.sidebar-shortcut ul li {
		.transition(all 0.7s ease 0.2s);

		.shortcut-grid i {
		    .transition(font-size 0.7s linear 0.5s);
		}
	}

	.sidebar-nav {
		.list-header, .list-divider, .nav-title,
		.arrow, .nav-title > .label {
			.transition-property(@transition-property);
			.transition-duration(@transition-duration);
			.transition-delay(@transition-delay);
			.transition-timing-function(@timing-function);
		}
	}
}
