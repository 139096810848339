.wizard {
	background-color: white;
	margin-bottom: 15px;
	.box-shadow(0px 2px 0px rgba(0, 0, 0, 0.05));

	.content, .actions {
		padding: 15px;
	}

	.steps {}

	.actions {
		text-align: right;
		background-color: #F0F2F4;
	}

	.title, .current-info {
		display: none;
		visibility: hidden;
	}

	.steps ul {
		width: 100%;

		&::before, &::after {
			display: table;
			content: " ";
		}
		&::after {
			clear: both;
		}

		li {
			position: relative;
			display: table-cell;
			width: 1%;
			text-align: center;

			&.current a, &.done a {
				background-color: @brand-info;
				color: white;
			}

			a {
				position: relative;
				display: block;
				padding: 20px 8px;
				color: @text-muted;
			}
		}
	}

	.actions ul {
		float: right;

		li {
			display: inline;

			&.disabled a {
				.opacity(.65);
			}

			a {
				position: relative;
				float: left;
				padding: 6px 12px;
				color: white;
				text-decoration: none;
				background-color: @brand-info;
				border: 1px solid #DDD;
			}
		}
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: outside none none;

		.disabled a {
			.opacity(.65);
			cursor: default;
		}
	}
}
