.table-row-color(@row-colour) {

    th, td {
        background-color: mix(white, @row-colour, 85%);
    }

    th > span {
        background-color: @row-colour;
    }
}

.table-row-border(@border-color) {
    > tbody {
        > tr {
            > td:first-child {
                border-left-color: @border-color;
            }
        }
    }
}

.table {

    &.table-primary, &.table-info, &.table-success, &.table-warning, &.table-danger {
        > tbody {
            > tr {
                > td:first-child {
                    border-left-width: 3px;
                    border-left-style: solid;
                }
            }
        }
    }

    > thead, > tbody, > tfoot {
        > tr {
            &.info {
                .table-row-color(@brand-info);
            }
            &.danger {
                .table-row-color(@brand-danger);
            }
            &.warning {
                .table-row-color(@brand-warning);
            }
            &.success {
                .table-row-color(@brand-success);
            }
            &.primary {
                .table-row-color(@brand-primary);
            }
        }
    }

    > thead {
        > tr {
            > th {
                border-bottom-color: #f5f5f5;
            }
        }
    }

    > tbody {
        > tr {
            > td {
                border-top-color: #f5f5f5;
            }
        }
    }

}


//== Table Colors
//## table specific colors.

.table-primary {
    .table-row-border(@brand-primary);
}

.table-info {
    .table-row-border(@brand-info);
}

.table-success {
    .table-row-border(@brand-success);
}

.table-warning {
    .table-row-border(@brand-warning);
}

.table-danger {
    .table-row-border(@brand-danger);
}


//== Tasks Table
//## tasks-table specific styling.

.tasks-table {
    border-collapse: separate;

    > thead {
        > tr {
            &.info, &.danger, &.warning, &.success, &.primary {
                th {
                    padding: 0;
                    border: none;

                    > span {
                        display: block;
                        width: 200px;
                        padding: 10px 17px;
                        color: white;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    > tbody {
        > tr {
            > td {
                padding: 15px 12px;

                h3, .h3 {
                    margin-top: 0;

                    .label {
                        font-size: 10px;
                        vertical-align: middle;
                        margin-left: 5%;
                    }
                }
                h4, .h4 {
                    margin-top: 5px;
                    font-weight: 300;
                }

                &:last-child {
                    width: 200px;
                    text-align: left;
                }

                .btn-group {
                	.btn {
                		border-radius: 0;
                		padding: 2px 8px;
                	}
                }
            }
        }
    }

}
