@shadow: 		0 1px 1px rgba(0,0,0,.05);
@check-shadow: 	0px -4px 0px 0px @light-green inset;

.heading-widget {
	display: inline-block;
	padding: 15px;
	margin-top: 15px;
	font-weight: 800;
	background-color: rgba(0,0,0,0.1);

	.fa {
		margin: 0 10px;
	}
}

.button-widget {
	padding: 0;

	.nav, .btn-group {
		margin: 0;

		&:after {
			content: '';
		}
	}

	.nav {
		li {
			a {
				border-radius: 0;
			}
		}
	}

	.btn-group {
		.btn {
			padding: 12px 15px;
			border: none;
			color: white;
			background-color: rgba(0,0,0,0.1);

			&:hover, &:focus {
				border: none;
				background-color: rgba(0,0,0,0.2);
			}

			&:first-child, &:last-child {
				border-radius: 0;
			}
		}

		.btn + .btn {
			margin: 0;
		}
	}
}

.time-widget {
	font-size: 35px;
	margin-top: 10px;

	span:last-child {
		font-size: 12px;
		font-weight: 800;
		vertical-align: text-top;
		line-height: 3;
	}
}

.contact-box {
	padding: 25px;
	background: white;
	margin-bottom: 15px;
	border-radius: 2px;
	.clearfix();
	.box-shadow(@shadow);

	.avatar {
		overflow: hidden;
		background-color: @clouds;
		.square(90px);

		img {
			max-height: 90px;
		}
	}

	h3 {
		margin-top: 0;
    	font-weight: 600;
	}

	address {
		margin-bottom: 0;
	}

	&:hover{
		.btn-group {
			display: block;
		}
	}

	.btn-group {
		position: absolute;
		top: 0px;
		right: 15px;
		margin: 0;
		display: none;
		background: white;
		.btn {
			border-radius: 0;
			padding: 2px 8px;
		}
	}
}

.notes-panel {
	.panel-body {
		max-height: 380px;
	}
}

.file-thumbnail {
	margin-bottom: 15px;
	background-color: white;
	border-radius: 3px;
	overflow: hidden;

	&.checked {
		.file-info {
			.box-shadow(@check-shadow);
		}
		.checkbox, .btn-group {
			display: block;
		}
	}

	.thumbnail-prev {
		text-align: center;
		max-width: 100%;
		max-height: 180px;
		overflow: hidden;
		background-color: @gray-lighter;

		img {
			max-width: 125%;
		}

		.file-icon {
			padding: 50px 0;

			i {
				font-size: 3em;
				color: @gray-light;
			}
		}

		&:hover {
			.checkbox, .btn-group {
				display: block;
			}
		}
	}

	.checkbox, .btn-group {
		position: absolute;
		top: 15px;
		display: none;
		margin: 0;
		background: white;

		.btn {
			border-radius: 0;
			padding: 2px 8px;
		}
	}

	.checkbox {
		left: 30px;
	}

	.btn-group {
		right: 30px;
	}

	.file-info {
		padding: 15px;
	}

	.file-name {
		margin-top: 0;
		margin-bottom: 10px;
	}
}

.quote {
	padding: 50px 70px;
	color: rgba(0,0,0,0.1);
	font-size: 3em;
}

.info-block {
	padding: 10px;
	padding-top: 0;

	.heading {
		text-transform: uppercase;
		font-weight: 500;
	}
}

.widgets-container {
	.widget-item {
		padding: 15px 0px;
		border-bottom: 1px solid @gray-lighter;

		&:first-child {
			padding-top: 0px;
		}

		&:last-child {
			border-bottom: none;
			padding-bottom: 0px;
		}

		.widget-title, .widget-info {
			display: block;
		}

		.widget-title span, .widget-info, .widget-time {
			color: @text-muted;
		}

		.widget-label, .widget-icon {
			float: right;
		}

		.widget-icon {
			color: white;
			padding: 7px;
			border-radius: 2px;
			font-size: 14px;
		}
	}
}
