.ll-skin-melon {
	font-size: 90%;
}

.ll-skin-melon .ui-widget {
	font-family: "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, Verdana, sans-serif;
	background: #2e3641;
	border: none;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.ll-skin-melon .ui-datepicker {
	padding: 0;
}

.ll-skin-melon .ui-datepicker-header {
	border: none;
	background: transparent;
	font-weight: normal;
	font-size: 15px;
}

.ll-skin-melon .ui-datepicker-header .ui-state-hover {
	background: transparent;
	border-color: transparent;
	cursor: pointer;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.ll-skin-melon .ui-datepicker .ui-datepicker-title {
	margin-top: .4em;
	margin-bottom: .3em;
	color: #e9f0f4;
}

.ll-skin-melon .ui-datepicker .ui-datepicker-prev-hover,
.ll-skin-melon .ui-datepicker .ui-datepicker-next-hover,
.ll-skin-melon .ui-datepicker .ui-datepicker-next,
.ll-skin-melon .ui-datepicker .ui-datepicker-prev {
	top: .9em;
	border:none;
}

.ll-skin-melon .ui-datepicker .ui-datepicker-prev-hover {
	left: 2px;
}

.ll-skin-melon .ui-datepicker .ui-datepicker-next-hover {
	right: 2px;
}

.ll-skin-melon .ui-datepicker .ui-datepicker-next span,
.ll-skin-melon .ui-datepicker .ui-datepicker-prev span {
	background-image: url(images/ui-icons_ffffff_256x240.png);
	background-position: -32px 0;
	margin-top: 0;
	top: 0;
	font-weight: normal;
}

.ll-skin-melon .ui-datepicker .ui-datepicker-prev span {
	background-position: -96px 0;
}

.ll-skin-melon .ui-datepicker table {
	margin: 0;
}

.ll-skin-melon .ui-datepicker th {
	padding: 1em 0;
	color: #ccc;
	font-size: 13px;
	font-weight: normal;
	border: none;
	border-top: 1px solid #3a414d;
}

.ll-skin-melon .ui-datepicker td {
	background: @brand-primary;
	border: none;
	padding: 0;
}

.ll-skin-melon td .ui-state-default {
	background: transparent;
	border: none;
	text-align: center;
	padding: .5em .25em;
	margin: 0;
	font-weight: normal;
	color: #efefef;
	font-size: 16px;
}

.ll-skin-melon .ui-state-disabled  {
	opacity: 1;
}

.ll-skin-melon .ui-state-disabled .ui-state-default {
	color: rgba(255,255,255, 0.4);
}

.ll-skin-melon td .ui-state-active,
.ll-skin-melon td .ui-state-hover {
	background: #2e3641;
}


.ui-datepicker {
	display: none;
	padding: 0;
	width: 240px;
	color: @gray;
	border-radius: 4px;
	overflow: hidden;

	&.ui-datepicker-inline {
		width: 100%;
	}

	.ui-datepicker-prev, .ui-datepicker-next {
		color: @clouds;
		cursor: default;
		font-family: FontAwesome;
		-webkit-font-smoothing: antialiased;
		font-style: normal;
		font-weight: normal;
		text-align: center;
	}

	.ui-datepicker-prev {
		&:before {
			content: "\f104";
		}
	}

	.ui-datepicker-next {
		&:before {
			content: "\f105";
		}
	}

}
