
.login-header {
	padding: 100px 0px;
	background-color: @brand-primary;
	transition: all 550ms ease-in-out 0s;
	color: white;
}

.login-content {
	width: 320px;
	padding: 20px 0;
	margin: 0 auto;
	text-align: center;
	color: white;

	.title {
		font-size: 25px;
	}

	.quote {
		font-size: 13px;
		margin-top: 20px;
        padding: 0;
        color: white;
	}
}

.login-form {
	transition: all 550ms ease-in-out 0s;
	padding-top: 30px;

	a {
		color: inherit;

		&:hover, &:focus {
			color: rgba(255, 255, 255, 0.3);
		}
	}

	.form-group {
		transition: all 550ms ease-in-out 250ms;
		margin-bottom: 20px;

		label {
			color: white;
		}

		.checkbox {
			margin: 0;

			> .icheckbox_square-blue {
				margin-right: 5px;
			}
		}

		.input-group {
			background: none;
			border: 1px solid rgba(255, 255, 255, 0.3);
			padding-top: 6px;
			padding-bottom: 6px;
			transition: all 300ms ease-in-out 0s;
			border-radius: 3px;

			.input-group-addon, .form-control {
				background: none repeat scroll 0% 0% transparent;
				border: 0px none;
				box-shadow: none;
				color: inherit;
			}

			.input-group-addon {
				border-right: 1px solid rgba(255, 255, 255, 0.3);
			}

			.form-control {
				padding: 6px 12px;

				.placeholder(rgba(255, 255, 255, 0.3));
				&:focus {
					box-shadow: none;
				}
			}
		}
	}

	.btn {
		border: 1px solid rgba(255, 255, 255, 0.3);
		padding: 15px 20px;
		font-size: 14px;
		transition: all 300ms ease-in-out 0s;
		color: white;
		white-space: normal;
		background: transparent;
		text-align: left;
		border-radius: 4px;

		.fa {
			float: right;
			padding: 3px 0;
		}

		&:hover, &:focus {
			background: rgba(255, 255, 255, 0.2);
		}
	}
}

@media(max-width: 991px)
{
	.login-header {
		padding: 20px 0;
	}
}
