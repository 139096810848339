
#aside-container {
    padding: 0px;
    background-color: #FFF;
    position: absolute;
    width: 220px;
    height: auto;
    left: auto;
    right: -220px;
    top: 0px;
    bottom: 0px;
    min-height: 100vh;
    overflow: hidden;
    visibility: hidden;
    z-index: 3;

	transition-property: width, padding, left, right;
	.transition-duration(0.35s);
}

#aside {
    max-height: none;
    padding-top: 50px;
    visibility: visible;
    background-color: #3C4D5E;
    height: 100%;
	color: #FFF;
	overflow: hidden;
	.transition( all 0.5s ease 0s);

	.tab-content {
	    padding: 10px 0px;
	}

	.nav-tabs {
		.active a {
		    border-left: 1px solid rgba(0, 0, 0, 0.1);
		    border-right: 1px solid rgba(0, 0, 0, 0.1);
		    border-bottom: 1px solid transparent;
		    background-color: transparent;
		    color: inherit !important;
		}

		li:not(active) a {
		    border-radius: 0px;
		    border-width: 0px 0px 1px;
		    border-style: none none solid;
		    border-color: -moz-use-text-color -moz-use-text-color rgba(0, 0, 0, 0.05);
		    -moz-border-top-colors: none;
		    -moz-border-right-colors: none;
		    -moz-border-bottom-colors: none;
		    -moz-border-left-colors: none;
		    border-image: none;
		    background-color: #364554;
		    color: rgba(255, 255, 255, 0.5);
		    padding: 20px 0px;
		}

		a i {
		    font-size: 1.35em;
		}

		.label, .badge {
		    margin-left: 4px;
		}
	}

	a:not(.btn):not(.btn-link) {
	    color: inherit;
	}

	h4 {
		padding-left: 15px;
		padding-right: 15px;
		font-weight: 300;
	}

	.list-group {
		background-color: transparent;

		.list-group-item {
			&:not(.active):not(.disabled) {
			    background-color: transparent;
			    border-color: transparent;
			    color: inherit;
			}

			&:hover:not(.active) {
			    background-color: rgba(0, 0, 0, 0.05);
			}

			&:first-child {
			    border-top-left-radius: 0px;
			    border-top-right-radius: 0px;
			}

			&:last-child {
			    border-bottom-left-radius: 0px;
			    border-bottom-right-radius: 0px;
			}
		}
	}

	hr {
	    border-color: rgba(0, 0, 0, 0.05);
	}

	.img-xs {
	    width: 32px;
	    height: 32px;
	}
	.text-lg {
	    font-size: 1.2em;
	}
	.text-muted {
	    color: #93A6B9;
	}
}

.aside-in {
	#aside-container {
	    left: auto;
	    right: 0px;
	}

	#aside {
	    padding-top: 50px;
	}
}
