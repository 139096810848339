.badge {
    font-weight: normal;
    color: white;

    &.badge-icon:empty {
        display: inline-block;
        width: 0.7em;
        height: 0.7em;
        padding: 0px;
        min-width: 5px;
        margin: 0.5em;
        border-radius: 50%;
    }

    &.badge-fw, &.badge-fw:empty {
        margin-right: 1em;
    }
}
