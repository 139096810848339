.breadcrumb {
    border-radius: 0px;
    background-color: transparent;
    margin-bottom: 10px;
    padding: 0px;

	li, li a {
		font-size: 0.85em;
		color: @gray-light;
	}

	> li + li::before {
		content: "\f105";
		display: inline-block;
		font-family: FontAwesome;
		font-style: normal;
		font-weight: normal;
		line-height: 1;
	}

	> .active {
		color: @gray-dark;
	}
}
