@transition-propterty-base: width, padding, left, right;

a, a:hover, a:focus {
	text-decoration: none;
}

.page-container {
	.square(100%);
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	background: none repeat scroll 0% 0% #f9f9f9;

	&.sidebar-lg, &.sidebar-sm  {
		.sidebar-container {
			left: 0px;
		}
	}

	.navbar-menu {
		width: 100%;
		position: relative;
	}

	.boxed {
		padding-top: 50px;
	}

	.sidebar-container, .main-container {
		vertical-align: top;
		box-sizing: border-box;
	}

	.sidebar-container {
		.transition-property(@transition-propterty-base);
		.transition-duration( 0.35s );
		position: absolute;
		left: -220px;
		padding-top: 50px;
		top: 0;
		bottom: 0;
		min-height: 100%;
		width: 220px;
	}

	&.sidebar-sm {
		.sidebar-container, .navbar .navbar-brand {
		    width: 50px;
		}
	}

	.main-container {
		background: none repeat scroll 0% 0% #f9f9f9;

		.page-heading {
			display: table;
			table-layout: fixed;
			height: 51px;
			padding: 15px 25px;
			width: 100%;

			.page-header {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				border: 0px none;
				font-size: 2.2em;
				font-weight: 100;
				margin: 0px;
				padding: 10px 0px;
			}

			.heading-content {
				text-align: right;
				width: 300px;
			}

			.page-header, .heading-content {
				display: table-cell;
				vertical-align: middle;
			}
		}

		.page-sub-heading {
			display: block;
			padding: 0 25px;
		}

		.main-content {
			padding: 20px 25px;
		}
	}

}
