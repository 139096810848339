.alert-overlay {
	position: absolute;
	display: block;
	top: 0px;
	left: 0px;
	z-index: 100100;
	width: 100%;
	border-radius: 0;
	height: 50px;

	.fa {
		
	}
}

.alert-success {
	.alert-variant(@brand-success; darken(@brand-success, 3%); white);
}

.alert-danger {
	.alert-variant(@brand-danger; darken(@brand-danger, 3%); white);
}

.alert-warning {
	.alert-variant(@brand-warning; darken(@brand-warning, 3%); white);
}

.alert-primary {
	.alert-variant(@brand-primary; darken(@brand-primary, 3%); white);
}

.alert-info {
	.alert-variant(@brand-info; darken(@brand-info, 3%); white);
}
