.list-header {
	font-weight: 300;
	padding: 10px 15px;
	position: relative;
}

.timeline {
	position: relative;
	padding-bottom: 40px;
	color: #515151;

	&::before, &::after {
	    background-color: #CAD3DF;
	    bottom: 20px;
	    content: "";
	    display: block;
	    position: absolute;
	}

	&::before {
	    left: 49px;
	    top: 20px;
	    width: 2px;
	}

	&::after {
	    left: 47px;
	    width: 6px;
	    height: 6px;
	    border-radius: 50%;
	}

	.timeline-header {
	    border-radius: 0px;
	    clear: both;
	    margin-bottom: 50px;
	    margin-top: 0;
	    position: relative;

		&::first-child {
		    margin-bottom: 30px;
		    margin-top: 15px;
		}

		.timeline-header-title {
		    display: inline-block;
		    text-align: center;
		    padding: 7px 15px;
		    min-width: 100px;
		}
	}

	.timeline-entry {
	    margin-bottom: 50px;
	    margin-top: 5px;
	    position: relative;
	    clear: both;
	}

	.timeline-stat {
	    width: 100px;
	    float: left;
	    text-align: center;
	    padding-bottom: 15px;
	}

	.timeline-icon {
	    border-radius: 50%;
	    box-shadow: 0px 0px 0px 7px #FFF;
	    display: block;
	    margin: 0px auto;
	    height: 46px;
	    line-height: 46px;
	    text-align: center;
	    width: 46px;

		&:empty {
		    height: 10px;
		    width: 10px;
		    margin-top: 20px;
		    background-color: #A4B4C7;
		}
	}

	.timeline-time {
	    display: inline-block;
	    padding: 5px 3px 7px;
	    margin-top: 3px;
	    background-color: #FFF;
	    color: #848484;
	    font-size: 0.85em;
	    max-width: 70px;
	}

	.timeline-label {
	    background-color: #FFF;
	    border-radius: 0px;
	    margin-left: 90px;
	    padding: 10px;
	    position: relative;
	    min-height: 50px;
	    border: 1px solid #E9E9E9;
	    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03);

		&::before, &::after {
		    content: "";
		    display: block;
		    position: absolute;
		    width: 0px;
		    height: 0px;
		    left: 0px;
		    top: 0px;
		}

		&::before {
		    border-top: 10px solid transparent;
		    border-bottom: 10px solid transparent;
		    border-right: 10px solid #E6E6E6;
		    margin: 15px 0px 0px -10px;
		}

		&::after {
		    border-top: 9px solid transparent;
		    border-bottom: 9px solid transparent;
		    border-right: 9px solid #FFF;
		    margin: 15px 0px 0px -8px;
		}
	}
}

.tasks-list {
	margin: 0;
	
	li {
		padding: 10px 0;

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}

		.task {
			padding-left: 10px;
		}

		.label {
			float: right;
		}

		.checked + .task {
			text-decoration: line-through;
			opacity: 0.5;
		}

		.collapse, .collapsing {
			margin: 0 -20px;
		}

		.widget-collapse {
			margin-top: 10px;
			padding: 10px 20px;
			background-color: #eee;
		}
	}
}
